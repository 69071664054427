body {
  margin: 0;
  font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', 'Kohinoor Devanagari', 'sans-serif',
    sans-serif;
  font-size: 12px;
  line-height: 17px;
  color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
