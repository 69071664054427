@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header-container {
  font-family: 'system','-apple-system','system-ui','BlinkMacSystemFont','Helvetica Neue','Helvetica','Arial','Lucida Grande','Kohinoor Devanagari',sans-serif;
  background-color: rgb(177, 0, 25);
  color: white;
  width: 100%;
  font-size: 3em;
  padding: 6px;
  display: flex;
  justify-content: center;
  position: fixed;
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  float: right;
}

.icon-des {
  height: 30px;
  vertical-align: middle;
  position: absolute;
  top: 15%;
  bottom: 15%;
}

.image-box {
  height: 50px;
  float: left;
}

.description-box {
  height: 50px;
  width: 100px;
  float: left;
  position: relative;
  padding-left: 15px;
}

.webChat {
  margin: 0 auto;
  padding: 0px;
  position: fixed;
  bottom: 0;
  padding-bottom: 0px;
  max-width: 100%;
  max-height: calc(100% - 60px);;
}

.webChat {
  height: 93vh;
  width: 100%;
  margin-top: 70px;
}

.webchat__bubble__content {
  font-family: 'system','-apple-system','system-ui','BlinkMacSystemFont','Helvetica Neue','Helvetica','Arial','Lucida Grande','Kohinoor Devanagari',sans-serif;
  border-style:  none;
  border-radius: 7.5px !important;
  box-shadow: 0px 1px .5px rgba(0,0,0,.13) !important;
}

.webchat__basic-transcript__activity {
  margin-bottom: 2px !important;
  margin-top: 0px !important;
}

.webchat__adaptive-card-renderer .ac-container .ac-adaptiveCard{
  padding: 0 !important;
}
/* bot bubble for bot activity */
.webchat__stacked-layout__message-row .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content 
{
  border-style: none !important;
  background-color: #FFF;
  font-size: 12px !important;
  min-height: auto !important;
  padding: 5px 8px 5px 8px;
  max-width: 100%;
  display: inline-block !important;
}

.webchat__carousel-filmstrip__attachment{
  flex:0;
  max-width: 40px !important;
}

/* bot bubble for Adaptive, attachment and carousel*/
.webchat__stacked-layout__attachment-row .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content,
.webchat__carousel-filmstrip__attachment .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content 
{
  border-style: none !important;
  background-color: #FFF;
  font-size: 12px !important;
  min-height: auto !important;
  max-width: 250px;
  display: inline-block !important;
}

/* user bubbles */
.webchat__bubble--from-user .webchat__bubble__content{
  background-color:rgb(177, 0, 25) !important;
  color: #FFF !important;
  font-weight: 500 !important;
  border-radius: 7.5px !important;
  border-style: none !important;
  padding: 5px 5px 3px 16px;
  font-size: 12px;
  min-height: auto !important;
}

/*Space between bubbles*/
.webchat__stacked-layout__message {
  padding: 2px;
}

/* No padding on user bubbles*/
.webchat__bubble__content .plain {
  padding: 0px !important
}

/* No padding on bot bubbles */
.webchat__bubble__content .markdown {
  padding: 0px !important
}

/* Suggested action row top padding */
.webchat__suggested-action{
  padding: 1px 6px !important;
}

/* Suggested button style */
.webchat__suggested-action button {
  border-radius: 20px !important;
  border-style: none !important;
  box-shadow: 0px 1px .5px rgba(0,0,0,.13) !important;
  background: rgba(255,255,255,.7) !important;
  color: #00a5f4;
  cursor: pointer;
  font-weight: 600;  
}

.ac-container .ac-adaptiveCard {
  padding: 0px !important;
}

/* Adaptive Card image */
.ac-container .ac-container img {
  height: 150px !important;
  width : 150px !important; 
}

/* Adaptive Card - Price, Total, Tax, etc */
.ac-adaptiveCard .ac-textBlock {
  color: rgb(49, 49, 49) !important;
  text-align: center !important;
  font-size: 12px !important;
}

/* Adaptive Card - Product Name */
.ac-adaptiveCard .ac-textBlock:first-child {
  color: rgb(90, 90, 90) !important;
  margin-top: 12px;
  margin-bottom: 12px;
  height: 3em !important;
  max-height: 3em !important;
  display: table-cell !important;
  vertical-align: middle !important;
  line-height: 1.5em !important;
}

/* last element adds a margin */
.ac-adaptiveCard .ac-textBlock:last-child {
  margin-bottom: 0px;
}

/** Adaptive Card Buttons **/
/* Catalogue buttons */

.ac-adaptiveCard .ac-pushButton {
  color: rgb(54, 54, 54) !important;
  border-radius: 22px !important;
  border-style: none !important;
  box-shadow: 0px 1px .5px rgba(0,0,0,.13) !important;
  margin-top: 5px !important; 
  margin-bottom: 5px !important;
  padding: 6px !important;
}

.ac-adaptiveCard .ac-pushButton div {
  max-height: 3em !important;
  display: table-cell !important;
  white-space:normal !important;
  line-height: 1.3em !important;
}

.style-positive {
  background-color:rgb(226, 226, 226) !important;
}

.style-positive:active, .style-positive:focus {
  background-color:rgb(167, 209, 243) !important;
}

.style-destructive {
  background-color:rgb(190, 190, 190) !important;
} 

.style-destructive:active, .style-destructive:focus {
  background-color:rgb(238, 188, 188) !important;
} 

.webchat__initialsAvatar {
  background-color: white !important;
}

.ac-image {
  padding: 2px;
}

.ac-horizontal-separator{
  height: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

